import {
  CardActions,
  Button,
  Dialog,
  useMediaQuery,
  DialogTitle as _DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import CourseCardContent, { Card } from "@studytools/core/ui/components/Course/CourseCardContent";
import { getWatchCourseUrl } from "@studytools/core/utils/navigationUtils";
import CourseContent from "@studytools/core/ui/components/Course/CoursePage/CourseContent";
import React, { FC, useState } from "react";
import WatchArrow from "../../../images/icons/watchArrow.png";
import { Course } from "@studytools/core/generated/graphql";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { useLessonAccess } from "@studytools/core/ui/hooks/useCourseItemAccess";

type Props = {
  course: Course;
  className?: string;
};

const CourseCard: FC<Props> = ({ course }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [modalIsOpen, setIsOpen] = useState(false);
  const { map } = course;

  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleCourseClick = event => {
    event.preventDefault();
    event.stopPropagation();
    const url = getWatchCourseUrl(course.id);
    window.location.href = `/app${url}`;
  };

  const { getLessonUserType } = useLessonAccess(map ? map.courseItems : undefined);

  return (
    <>
      <Card>
        <CourseCardContent course={course} onClick={handleOpen} />
        <CardActions>
          <Button onClick={handleOpen} color="primary">
            Read more
          </Button>
          <Button style={{ marginLeft: "auto" }} onClick={handleCourseClick} color="primary">
            Watch now <img src={WatchArrow} />
          </Button>
        </CardActions>
      </Card>
      <Dialog open={modalIsOpen} onClose={handleClose} maxWidth="xl" fullScreen={fullScreen}>
        <DialogTitle id="scroll-dialog-title">
          <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <FontAwesomeIcon
              style={{
                height: 20,
                width: 20,
              }}
              icon={faTimes}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true} style={{ border: "none" }}>
          <CourseContent course={course} getLessonUserType={getLessonUserType} />
        </DialogContent>
      </Dialog>
    </>
  );
};
export default CourseCard;

const DialogTitle = styled(_DialogTitle)`
  > h2 {
    text-align: right;
  }
`;
