import React, { FC } from "react";
import { Card as _Card, CardMedia, CardContent, Typography, CardActionArea, styled } from "@material-ui/core";
import EllipsisText from "react-ellipsis-text";
import { Course } from "../../../generated/graphql";

type Props = {
  course: Course;
  onClick: () => void;
};

const CourseCardContent: FC<Props> = ({ course, onClick }) => {
  return (
    <CardActionArea style={{ height: "auto" }} onClick={onClick}>
      <CardMedia style={{ height: 190 }} image={course.thumbnailURL} title={course.name || ""} />
      <CardContent style={{ height: 160 }}>
        <Typography variant="h6">{course.name}</Typography>
        <Typography gutterBottom variant="subtitle2" color="textSecondary">
          {course.author}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {course.description && <EllipsisText text={course.description} length={130} />}
        </Typography>
      </CardContent>
    </CardActionArea>
  );
};
export const Card = styled(_Card)({
  minWidth: 300,
  maxWidth: 352,
});
export default CourseCardContent;
