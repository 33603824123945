import { Typography } from "@material-ui/core";
import React, { FC, useContext } from "react";
import styled from "styled-components";
import _Button from "../../Button";
import HistoryContext from "../../../context/HistoryContext";
import { UserType } from "../../../../constant/userType";

type Props = {
  onLogin: () => void;
};

const SignUpOverlay: FC<Props> = ({ onLogin }) => {
  const history = useContext(HistoryContext);

  return (
    <Container>
      <div style={{ zIndex: 3, padding: 16 }}>
        <Button
          fullWidth={false}
          height="medium"
          onClick={() => history.push(`/sign-up`, UserType.AllUsers)}
          color="primary"
        >
          <Typography variant="body1" style={{ margin: "0 16px" }} align="center">
            Register for free for full content
          </Typography>
        </Button>
        <div style={{ textAlign: "center" }}>
          <Typography
            variant="body1"
            style={{ display: "inline", backgroundColor: "white", boxShadow: "0 0 10px white" }}
          >
            Already registered?{" "}
            <span
              role="button"
              tabIndex={0}
              onClick={onLogin}
              onKeyDown={onLogin}
              style={{ color: "#0055D7", cursor: "pointer" }}
            >
              Login
            </span>
          </Typography>
        </div>
      </div>
      <Backdrop />
    </Container>
  );
};

const Backdrop = styled.div({
  position: "absolute",
  opacity: 0.8,
  backgroundColor: "white",
  width: "100%",
  height: "100%",
  zIndex: 2,
});

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Button = styled(_Button)`
  margin: auto;
  min-width: 205px;
`;

export default SignUpOverlay;
