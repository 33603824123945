import { CourseAccess } from "../entity/Course";
import { Course, Map } from "../generated/graphql";

export const isPublicCourse = (course: Course | null): boolean => {
  return course != null && (course.access === CourseAccess.Public || course.access === CourseAccess.PublicNotListed);
};

export const getCourseName = (course: Course | null, map: Map): string => {
  // The course name is only reliable if it's a public course
  if (course && isPublicCourse(course)) {
    return course.name;
  }
  return map.name;
};
