import React, { CSSProperties, FC, ReactElement } from "react";
import { Grid, makeStyles, Tooltip, Theme } from "@material-ui/core";
import { Placement, TooltipData } from "./types";

type Props = {
  data?: TooltipData;
  containerStyle?: CSSProperties;
  children: ReactElement;
  placement?: Placement;
  open?: boolean;
  enterDelay?: number;
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
    title: {
      fontSize: 14,
      padding: 2,
    },
    detail: {
      fontSize: 12,
      padding: 2,
    },
  };
});

const DefaultContainerStyle: CSSProperties = { maxWidth: 200 };

export const ExtendedTooltip: FC<Props> = ({
  data,
  placement = "right",
  containerStyle = DefaultContainerStyle,
  children,
  open,
  enterDelay = 700,
}) => {
  const classes = useStyles();
  if (!data || !data.title) {
    return <>{children}</>;
  }

  return (
    <Tooltip
      enterDelay={enterDelay}
      enterNextDelay={500}
      classes={{ tooltip: classes.tooltip }}
      open={open}
      title={
        <Grid container direction="column" style={containerStyle}>
          <Grid item className={classes.title}>
            {data.title}
          </Grid>
          {data.detail && (
            <Grid item className={classes.detail}>
              {data.detail}
            </Grid>
          )}
        </Grid>
      }
      placement={placement}
    >
      {children}
    </Tooltip>
  );
};
