import React, { FC, memo } from "react";
import { ListItemText } from "@material-ui/core";
import useNavigation from "../../../../hooks/useNavigation";
import { Quiz } from "../../../../../generated/graphql";
import { DefaultIconStyle } from "../../../icons/styles";
import IconQuizRound from "../../../icons/IconQuizRound.svg";
import { ExtendedTooltip } from "../../../ExtendedTooltip/ExtendedTooltip";
import ProgressButton from "../../../ProgressButton";
import { CourseItemListItem } from "./CourseItemListItem";

type Props = {
  courseId: string | undefined;
  mapId: string;
  quiz: Quiz;
  answeredQuestions: number;
  totalQuestions: number;
};

const CourseQuizItem: FC<Props> = ({ courseId, mapId, quiz, answeredQuestions, totalQuestions }) => {
  const { goToQuestion } = useNavigation(courseId, mapId);

  const handlePlayLesson = () => goToQuestion(quiz.id);

  const progress = totalQuestions > 0 ? (answeredQuestions / totalQuestions) * 100 : 0;

  return (
    <ExtendedTooltip
      data={{
        title: "Open quiz",
      }}
    >
      <CourseItemListItem divider button onClick={handlePlayLesson}>
        <ProgressButton progress={progress} style={{ marginRight: 16 }} progressColor="#8F60DD" testId="quiz-btn">
          <img src={IconQuizRound} alt="Quiz" style={DefaultIconStyle} />
        </ProgressButton>
        <ListItemText primary={quiz.name} />
      </CourseItemListItem>
    </ExtendedTooltip>
  );
};

export default memo(CourseQuizItem);
