import React, { FC, useState } from "react";
import styled from "styled-components";
import { Container } from "@material-ui/core";
import { isEmpty } from "lodash";
import { RequiredLessonUserType } from "../../../../src/@types/courseItem";
import { Course } from "../../../../generated/graphql";
import CoursePageTitle from "./CoursePageTitle";
import CourseThumbnail from "./CourseThumbnail";
import LessonAndPresentation from "./LessonAndPresentation";
import CourseSummaryActions from "./CourseSummaryActions";
import CourseItemList from "./CourseItemList/CourseItemList";
import PresentationList from "./PresentationList";
import { PresentationType } from "../../../../constant/presentationType";
import ShareModal from "../../modals/ShareModal";
import { getExternalSummaryUrl } from "../../../../utils/externalNavigationUtils";

type Props = {
  course: Course;
  hasFlashCards?: boolean;
  getLessonUserType: RequiredLessonUserType;
};

const CourseContent: FC<Props> = ({ course, hasFlashCards, getLessonUserType }) => {
  const [showShareModal, setShowShareModal] = useState(false);
  const [expandedModuleId, setExpandedModuleId] = useState<string>();
  if (course.map == null) {
    return null;
  }
  const { presentations, courseItems } = course.map;
  const standardPresentations = presentations.filter(p => p.type === PresentationType.STANDARD);
  const hasCourseItems = !isEmpty(courseItems);
  const hasPresentations = !isEmpty(standardPresentations);
  let gridClass = "";
  if (!hasCourseItems && !hasPresentations) gridClass = "noList";
  const url = getExternalSummaryUrl(undefined, course.id);
  const handleShareClick = () => {
    setShowShareModal(true);
  };
  return (
    <Container maxWidth="lg" style={{ padding: "0 24px 24px 24px" }}>
      <CoursePageTitle course={course} handleShareClick={handleShareClick} />
      {showShareModal && (
        <ShareModal
          onClose={() => {
            setShowShareModal(false);
          }}
          url={url}
        />
      )}
      <GridContainer className={gridClass}>
        {(hasCourseItems || hasPresentations) && (
          <PaddedDiv>
            <CourseThumbnail course={course} />
            <CourseSummaryActions
              course={course}
              hasLessons={hasCourseItems}
              hasPresentations={hasPresentations}
              standardPresentations={standardPresentations}
              hasFlashCards={hasFlashCards}
              getLessonUserType={getLessonUserType}
            />
          </PaddedDiv>
        )}
        {!hasCourseItems && !hasPresentations && (
          <>
            <PaddedDiv>
              <CourseThumbnail course={course} />
            </PaddedDiv>
            <div>
              <CourseSummaryActions
                course={course}
                hasLessons={hasCourseItems}
                hasPresentations={hasPresentations}
                standardPresentations={standardPresentations}
                getLessonUserType={getLessonUserType}
              />
            </div>
          </>
        )}
        <LessonAndPresentation defaultToShowingPresentations={!hasCourseItems && hasPresentations}>
          {{
            lessonList: hasCourseItems && (
              <CourseItemList
                courseId={course.id}
                map={course.map!}
                expandedModuleId={expandedModuleId}
                onToggleModule={moduleId => {
                  setExpandedModuleId(moduleId === expandedModuleId ? undefined : moduleId);
                }}
              />
            ),
            presentationList: hasPresentations && (
              <PresentationList courseId={course.id} mapId={course.mapId} presentations={standardPresentations} />
            ),
          }}
        </LessonAndPresentation>
      </GridContainer>
    </Container>
  );
};

export default CourseContent;

const GridContainer = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: auto;
  @media (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 720px) {
    display: block;
  }
  &.noList {
    grid-template-columns: 1fr 1fr;
    @media (max-width: 959px) {
      grid-template-columns: 1fr;
    }
  }
`;
const PaddedDiv = styled.div`
  padding-top: 24px;
`;
