import React, { FC } from "react";
import { List } from "@material-ui/core";
import { isEmpty } from "lodash";
import { Presentation } from "../../../../generated/graphql";
import useNavigation from "../../../hooks/useNavigation";
import PresentationItem from "./PresentationItem";

type Props = {
  courseId: string | undefined;
  mapId: string;
  presentations: Presentation[];
  onShowGroupPresentation?: (presentation: Presentation) => void;
};

const PresentationList: FC<Props> = ({ courseId, mapId, presentations, onShowGroupPresentation }) => {
  const { goToPresentation } = useNavigation(courseId, mapId);
  if (isEmpty(presentations)) return null;

  return (
    <List data-testid="presentation-list">
      {presentations.map(presentation => {
        return (
          <PresentationItem
            key={presentation.id}
            presentation={presentation}
            onGroupPresentationClick={onShowGroupPresentation}
            onClick={() => goToPresentation(presentation.id)}
          />
        );
      })}
    </List>
  );
};

export default PresentationList;
