import React, { FC } from "react";
import { ListItemText } from "@material-ui/core";
import { formatDistance } from "date-fns";
import styled from "styled-components";
import { IconPlay } from "../../../icons/IconPlay";
import { LessonItem } from "../../../../../generated/graphql";
import ProgressButton from "../../../ProgressButton";

type Props = {
  lesson: LessonItem;
  duration?: number | null;
  lessonProgress: number;
};

const LessonItemSummary: FC<Props> = ({ lesson, duration, lessonProgress }) => {
  return (
    <>
      <Container>
        <ProgressButton progress={lessonProgress >= 90 ? 100 : lessonProgress} progressColor="#1AD7CB">
          <IconPlay />
        </ProgressButton>
      </Container>
      <ListItemText primary={lesson.name} secondary={duration ? formatDistance(new Date(duration), new Date(0)) : ""} />
    </>
  );
};

const Container = styled.span({ marginRight: 16 });

export default LessonItemSummary;
