import React, { FC, useContext, useMemo } from "react";
import { List as MuiList, withStyles } from "@material-ui/core";
import { isEmpty } from "lodash";
import { useLessonAccess } from "../../../../hooks/useCourseItemAccess";
import { UserType } from "../../../../../constant/userType";
import HistoryContext from "../../../../context/HistoryContext";
import { NumberOfPreviewLessons } from "../../../../../constant/anonymousRestrictions";
import { MapProgress, Map, AnswerAttempt } from "../../../../../generated/graphql";
import SignUpOverlay from "../SignUpOverlay";
import CourseModuleItem from "./CourseModuleItem";
import ModuleItem from "./ModuleItem";
import { getModuleProgress } from "./moduleProgress";

const List = withStyles({ root: { backgroundColor: "#f0f0f0", marginTop: 6, marginBottom: 6 } })(MuiList);

export type Props = {
  courseId?: string;
  map: Map;
  isLoggedIn?: boolean;
  mapProgress?: MapProgress | null;
  flashcardProgress?: Record<string, { correct: number; attempted: number }> | undefined;
  mapAnswers?: AnswerAttempt[];
  expandedModuleId: string | undefined;
  onToggleModule: (moduleId: string) => void;
};

const CourseItemList: FC<Props> = ({
  courseId,
  map,
  isLoggedIn,
  mapProgress,
  mapAnswers = [],
  flashcardProgress = {},
  expandedModuleId,
  onToggleModule,
}) => {
  const history = useContext(HistoryContext);
  const { courseItems, presentations } = map;
  const handleLogin = () => {
    history.push(`/login?courseId=${courseId}`, UserType.AllUsers);
  };

  const moduleProgress = useMemo(() => getModuleProgress(mapAnswers, mapProgress, courseItems, presentations), [
    mapProgress,
    mapAnswers,
    courseItems,
    presentations,
  ]);

  const { getLessonUserType } = useLessonAccess(courseItems);

  if (isEmpty(courseItems)) return null;

  return (
    <List data-testid="course-item-list" disablePadding>
      {courseItems.slice(0, isLoggedIn ? undefined : NumberOfPreviewLessons).map(item => {
        if (item.__typename === "CourseModule") {
          const expanded = item.id === expandedModuleId;
          return (
            <CourseModuleItem
              key={item.id}
              expanded={expanded}
              onToggleExpand={onToggleModule}
              courseModule={item}
              map={map}
              courseId={courseId}
              mapAnswers={mapAnswers}
              mapProgress={mapProgress}
              flashcardProgress={flashcardProgress}
              moduleProgress={moduleProgress ? moduleProgress.find(prog => prog.id === item.id) : undefined}
              getLessonUserType={getLessonUserType}
            />
          );
        }

        return (
          <ModuleItem
            key={item.id}
            courseItem={item}
            map={map}
            courseId={courseId}
            mapAnswers={mapAnswers}
            mapProgress={mapProgress}
            getLessonUserType={getLessonUserType}
            flashcardProgress={flashcardProgress}
          />
        );
      })}
      {!isLoggedIn && (
        <div style={{ position: "relative" }} data-testid="locked-items">
          <SignUpOverlay onLogin={handleLogin} />
          {courseItems.slice(NumberOfPreviewLessons).map(item => {
            if (item.__typename === "CourseModule") {
              const expanded = item.id === expandedModuleId;
              return (
                <CourseModuleItem
                  key={item.id}
                  expanded={expanded}
                  onToggleExpand={onToggleModule}
                  courseModule={item}
                  map={map}
                  courseId={courseId}
                  getLessonUserType={getLessonUserType}
                />
              );
            }

            return (
              <ModuleItem
                key={item.id}
                courseItem={item}
                map={map}
                courseId={courseId}
                getLessonUserType={getLessonUserType}
              />
            );
          })}
        </div>
      )}
    </List>
  );
};

export default CourseItemList;
