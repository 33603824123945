import { useCallback } from "react";
import { isLogonRequiredToViewLesson } from "../../utils/courseItemsUtils";
import { UserType } from "../../constant/userType";
import { CourseItem } from "../../generated/graphql";
import { RequiredLessonUserType } from "../../src/@types/courseItem";

export function useLessonAccess(courseItems: CourseItem[] | undefined): { getLessonUserType: RequiredLessonUserType } {
  const getLessonUserType = useCallback(
    (lessonId: string): UserType => {
      if (isLogonRequiredToViewLesson(courseItems, lessonId)) return UserType.LoggedOnOnly;
      return UserType.AllUsers;
    },
    [courseItems]
  );
  return { getLessonUserType };
}
