import React, { FC, CSSProperties } from "react";
import { Grid, ThemeProvider, Typography } from "@material-ui/core";
import { toLower } from "lodash";
import { RequiredLessonUserType } from "../../../../src/@types/courseItem";
import { getCourseItemTypeName } from "../../../../constant/courseItemType";
import { includesRight } from "../../../../utils/accessRightUtils";
import { AccessRight } from "../../../../constant/accessRights";
import { ModuleItem } from "../../../../generated/graphql";
import useNavigation from "../../../hooks/useNavigation";
import Button from "../../Button";
import theme from "../../../theme/muiTheme";
import { ExtendedTooltip } from "../../ExtendedTooltip/ExtendedTooltip";

const gridItemStyle: CSSProperties = { width: "100%", height: 64, display: "flex", justifyContent: "space-between" };
type Props = {
  courseId: string | undefined;
  mapId: string;
  nextCourseItem?: ModuleItem;
  presentationId?: string;
  title: string;
  hasLessons: boolean;
  accessRight: AccessRight;
  hasAdminRights?: boolean;
  hasFlashCards?: boolean | undefined;
  getLessonUserType: RequiredLessonUserType;
};
const CourseActions: FC<Props> = ({
  courseId,
  mapId,
  nextCourseItem,
  presentationId,
  title,
  hasLessons,
  accessRight,
  hasAdminRights = false,
  hasFlashCards,
  getLessonUserType,
}) => {
  const {
    goToMap,
    goToRecall,
    goToStudyCard,
    goToLesson,
    goToPresentation,
    goToCourseReport,
    goToUsageReport,
  } = useNavigation(courseId, mapId);

  const showReport = includesRight(AccessRight.FACILITATOR, accessRight) || hasAdminRights;
  const showCourseReport = showReport && hasLessons;
  const showCourseAndUsageReports = showCourseReport && showReport;

  // Button Tooltips
  const tooltips = {
    nextCourseItem: {
      title: `Watch the next ${nextCourseItem ? toLower(getCourseItemTypeName(nextCourseItem)) : ""}`,
    },
    watchPresentation: {
      title: "Watch a presentation",
      detail: `Watch a presentation for ${title}`,
    },
    viewMap: {
      title: "View the map",
      detail: `View the map for ${title}`,
    },
    flashcards: {
      title: "Try flashcards",
      detail: `Test your knowledge with flashcards for ${title}`,
    },
    recallMap: {
      title: "Try recall the map",
      detail: `Try recalling the nodes from ${title}`,
    },
    usage: {
      title: "View usage report",
      detail: `View the usage report for ${title}`,
    },
    courseReport: {
      title: "View the course report",
      detail: `View the course report for ${title}`,
    },
  };
  return (
    <ThemeProvider theme={theme}>
      <Grid container item spacing={2} style={{ paddingTop: 24 }}>
        {nextCourseItem && (
          <ExtendedTooltip data={tooltips.nextCourseItem}>
            <Grid item style={gridItemStyle}>
              <Button fill="blue" onClick={() => goToLesson(nextCourseItem.id, getLessonUserType)}>
                <Typography variant="h6">{`WATCH NEXT ${getCourseItemTypeName(nextCourseItem)}`}</Typography>
              </Button>
            </Grid>
          </ExtendedTooltip>
        )}
        {presentationId && !nextCourseItem && (
          <ExtendedTooltip data={tooltips.watchPresentation}>
            <Grid item style={gridItemStyle}>
              <Button fill="blue" onClick={() => goToPresentation(presentationId)}>
                <Typography variant="h6">WATCH PRESENTATION</Typography>
              </Button>
            </Grid>
          </ExtendedTooltip>
        )}
        <ExtendedTooltip data={tooltips.viewMap}>
          <Grid item style={gridItemStyle}>
            <Button fill="blue" onClick={goToMap}>
              <Typography variant="h6">VIEW MAP</Typography>
            </Button>
          </Grid>
        </ExtendedTooltip>
        <Grid container item wrap="nowrap" style={gridItemStyle} justify="space-between">
          {hasFlashCards && (
            <>
              <ExtendedTooltip data={tooltips.flashcards}>
                <Grid item style={{ width: "48.5%" }}>
                  <Button fill="grey" onClick={goToStudyCard}>
                    <Typography variant="h6">FLASHCARDS</Typography>
                  </Button>
                </Grid>
              </ExtendedTooltip>
              <ExtendedTooltip data={tooltips.recallMap}>
                <Grid item style={{ width: "48.5%" }}>
                  <Button fill="grey" onClick={() => goToRecall()}>
                    <Typography variant="h6">RECALL MAP</Typography>
                  </Button>
                </Grid>
              </ExtendedTooltip>
            </>
          )}
          {!hasFlashCards && (
            <ExtendedTooltip data={tooltips.recallMap}>
              <div style={{ width: "100%" }}>
                <Button fill="grey" onClick={() => goToRecall()}>
                  <Typography variant="h6">RECALL MAP</Typography>
                </Button>
              </div>
            </ExtendedTooltip>
          )}
        </Grid>
        {showReport && (
          <Grid container item wrap="nowrap" style={gridItemStyle} justify="space-between">
            {showCourseAndUsageReports && (
              <>
                <ExtendedTooltip data={tooltips.usage}>
                  <Grid item style={{ width: "48.5%" }}>
                    <Button fill="grey" onClick={() => goToUsageReport()}>
                      <Typography variant="h6">USAGE</Typography>
                    </Button>
                  </Grid>
                </ExtendedTooltip>
                <ExtendedTooltip data={tooltips.courseReport}>
                  <Grid item style={{ width: "48.5%" }}>
                    <Button fill="grey" onClick={() => goToCourseReport()}>
                      <Typography variant="h6">COURSE REPORT</Typography>
                    </Button>
                  </Grid>
                </ExtendedTooltip>
              </>
            )}
            {!showCourseAndUsageReports && (
              <>
                {showReport && (
                  <ExtendedTooltip data={tooltips.usage}>
                    <div style={{ width: "100%" }}>
                      <Button fill="grey" onClick={() => goToUsageReport()}>
                        <Typography variant="h6">USAGE</Typography>
                      </Button>
                    </div>
                  </ExtendedTooltip>
                )}
                {showCourseReport && (
                  <ExtendedTooltip data={tooltips.courseReport}>
                    <div style={{ width: "100%" }}>
                      <Button fill="grey" onClick={() => goToCourseReport()}>
                        <Typography variant="h6">COURSE REPORT</Typography>
                      </Button>
                    </div>
                  </ExtendedTooltip>
                )}
              </>
            )}
          </Grid>
        )}
      </Grid>
    </ThemeProvider>
  );
};

export default CourseActions;
