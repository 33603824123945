import React, { FC, memo } from "react";
import { ListItemText } from "@material-ui/core";
import { RecallItem } from "../../../../../generated/graphql";
import useNavigation from "../../../../hooks/useNavigation";
import ProgressButton from "../../../ProgressButton";
import { IconSitemap } from "../../../icons/IconSitemap";
import { CourseItemListItem } from "./CourseItemListItem";

type Props = {
  courseId: string | undefined;
  mapId: string;
  recallItem: RecallItem;
};

const CourseRecallItem: FC<Props> = ({ courseId, mapId, recallItem }) => {
  const { goToLessonRecall } = useNavigation(courseId, mapId);

  const handleRecall = () => goToLessonRecall(recallItem.id);

  return (
    <CourseItemListItem button divider onClick={handleRecall} data-testid="recall-item">
      <ProgressButton style={{ marginRight: 16 }}>
        <IconSitemap />
      </ProgressButton>
      <ListItemText primary={recallItem.name} />
    </CourseItemListItem>
  );
};

export default memo(CourseRecallItem);
