import React, { FC, useState, ReactNode } from "react";
import { Tabs, Tab, Paper, Switch, FormControlLabel } from "@material-ui/core";
import styled from "styled-components";

type Props = {
  editMode?: boolean;
  onModeChange?: false | ((isEditing: boolean) => void);
  defaultToShowingPresentations: boolean;
  children: {
    lessonList: ReactNode;
    presentationList: ReactNode;
    toolbar?: ReactNode;
  };
};

enum TabKey {
  Lesson,
  Presentation,
}

const LessonAndPresentation: FC<Props> = ({ children, onModeChange, editMode, defaultToShowingPresentations }) => {
  const { lessonList, presentationList, toolbar } = children;

  const showLessons = Boolean(lessonList);
  const showPresentations = Boolean(presentationList);
  const [tab, setTab] = useState(
    showLessons && (!defaultToShowingPresentations || !showPresentations) ? TabKey.Lesson : TabKey.Presentation
  );
  if (!showLessons && !showPresentations) return null;

  return (
    <Container>
      <Paper style={{ backgroundColor: "#F2F2F2", display: "flex" }}>
        <Tabs value={tab} onChange={(_e, newValue) => setTab(newValue)} indicatorColor="primary">
          {showLessons && <Tab value={TabKey.Lesson} label="Lesson" />}
          {showPresentations && <Tab value={TabKey.Presentation} label="Presentation" />}
        </Tabs>
        <span style={{ marginLeft: "auto" }}>
          {editMode && tab === TabKey.Lesson && toolbar}
          {onModeChange && (
            <FormControlLabel
              data-testid="edit-mode-switch"
              control={
                <Switch color="primary" checked={editMode} onChange={event => onModeChange(event.target.checked)} />
              }
              label="Edit"
            />
          )}
        </span>
      </Paper>
      {tab === TabKey.Lesson && lessonList}
      {tab === TabKey.Presentation && presentationList}
    </Container>
  );
};

const Container = styled.div({ paddingTop: 24 });

export default LessonAndPresentation;
