import { useCallback, useContext } from "react";
import { Identifier } from "../../Base/types";
import { RequiredLessonUserType } from "../../src/@types/courseItem";
import { ModuleItem } from "../../generated/graphql";
import { UserType } from "../../constant/userType";
import { PresentationSubMode } from "../../constant/presentationSubMode";
import HistoryContext from "../context/HistoryContext";
import { CourseItemType } from "../../constant/courseItemType";

function useNavigation(courseId: string | undefined, mapId: string) {
  const history = useContext(HistoryContext);
  const goToLesson = useCallback(
    (lessonId: string, getLessonUserType: RequiredLessonUserType) => {
      const userType = getLessonUserType(lessonId);
      if (courseId) history.push(`/course/${courseId}/lesson/${lessonId}`, userType);
      else history.push(`/${mapId}/lesson/${lessonId}`, userType);
    },
    [courseId, history, mapId]
  );

  const goToPresentation = useCallback(
    (presentationId: string) => {
      if (courseId) history.push(`/course/${courseId}/presentation/${presentationId}`, UserType.AllUsers);
      else
        history.push(
          `/${mapId}/presentation/${presentationId}/${PresentationSubMode[PresentationSubMode.Standalone]}`,
          UserType.AllUsers
        );
    },
    [courseId, history, mapId]
  );

  const goToQuestion = useCallback(
    (quizId: string) => {
      if (courseId) history.push(`/course/${courseId}/quiz/${quizId}`, UserType.LoggedOnOnly);
      else history.push(`/${mapId}/quiz/${quizId}`, UserType.LoggedOnOnly);
    },
    [courseId, history, mapId]
  );

  const goToMap = useCallback(() => {
    if (courseId) {
      history.push(`/course/${courseId}`, UserType.AllUsers);
    } else {
      history.push(`/${mapId}`, UserType.LoggedOnOnly);
    }
  }, [courseId, history, mapId]);

  const goToStudyCard = useCallback(() => {
    if (courseId) history.push(`/course/${courseId}/cards`, UserType.LoggedOnOnly);
    else history.push(`/${mapId}/cards`, UserType.LoggedOnOnly);
  }, [courseId, history, mapId]);

  const goToRecall = useCallback(
    (layerId?: Identifier) => {
      if (courseId)
        history.push(`/course/${courseId}/recall${layerId ? `/layer/${layerId}` : ""}`, UserType.LoggedOnOnly);
      else history.push(`/${mapId}/recall${layerId ? `/layer/${layerId}` : ""}`, UserType.LoggedOnOnly);
    },
    [courseId, history, mapId]
  );

  const goToLessonRecall = useCallback(
    (recallId: string) => {
      if (courseId) history.push(`/course/${courseId}/recall/${recallId}`, UserType.LoggedOnOnly);
      else history.push(`/${mapId}/recall/${recallId}`, UserType.LoggedOnOnly);
    },
    [courseId, history, mapId]
  );

  const goToLessonCards = useCallback(
    (cardId: string) => {
      if (courseId) history.push(`/course/${courseId}/cards/${cardId}`, UserType.LoggedOnOnly);
      else history.push(`/${mapId}/cards/${cardId}`, UserType.LoggedOnOnly);
    },
    [courseId, history, mapId]
  );

  const goToCourseReport = useCallback(() => {
    history.push(`/${mapId}/courseReport`, UserType.LoggedOnOnly);
  }, [history, mapId]);

  const goToGroupCourseReport = useCallback(
    (groupId: string) => {
      history.push(`/class/${groupId}/report/${mapId}`, UserType.LoggedOnOnly);
    },
    [history, mapId]
  );

  const goToUsageReport = useCallback(() => {
    history.push(`/${mapId}/usage`, UserType.LoggedOnOnly);
  }, [history, mapId]);

  const goToCourseItem = useCallback(
    (moduleItem: ModuleItem, getLessonUserType: RequiredLessonUserType) => {
      switch (moduleItem.__typename) {
        case CourseItemType.Lesson:
          goToLesson(moduleItem.id, getLessonUserType);
          break;
        case CourseItemType.Quiz:
          goToQuestion(moduleItem.id);
          break;
        default:
          console.error("Unsupported Item Type", moduleItem.__typename);
          break;
      }
    },
    [goToLesson, goToQuestion]
  );

  return {
    goToMap,
    goToCourseItem,
    goToLesson,
    goToLessonRecall,
    goToLessonCards,
    goToQuestion,
    goToPresentation,
    goToStudyCard,
    goToRecall,
    goToCourseReport,
    goToUsageReport,
    goToGroupCourseReport,
  };
}

export default useNavigation;
