import { CourseItemType } from "../constant/courseItemType";
import { NumberOfPreviewLessons } from "../constant/anonymousRestrictions";
import {
  CourseItem,
  CourseModule,
  FlashcardItem,
  LessonItem,
  ModuleItem,
  Quiz,
  RecallItem,
} from "../generated/graphql";

export const getItemType = (item: CourseItem): CourseItemType => {
  return item.__typename as CourseItemType;
};

const isOfType = (courseItem: CourseItem, type: CourseItemType): boolean => {
  return getItemType(courseItem) === type;
};

const findItemOfType = (
  courseItems: CourseItem[] | undefined,
  type: CourseItemType,
  id: string | undefined
): { item: CourseItem; index: number; moduleIndex?: number } | undefined => {
  if (courseItems && id) {
    for (let index = 0; index < courseItems.length; index += 1) {
      const item = courseItems[index];
      if (item.__typename === type && item.id === id) {
        return { item, index };
      }
      if (item.__typename === CourseItemType.CourseModule) {
        if (item.items) {
          for (let moduleIndex = 0; moduleIndex < item.items.length; moduleIndex += 1) {
            const moduleItem = item.items[moduleIndex];
            if (moduleItem.__typename === type && moduleItem.id === id) {
              return { item: moduleItem, index, moduleIndex };
            }
          }
        }
      }
    }
  }
  return undefined;
};

const findItemsOfType = (courseItems: CourseItem[], type: CourseItemType): CourseItem[] | undefined => {
  return courseItems.reduce<CourseItem[]>((results: CourseItem[], courseItem: CourseItem) => {
    if (courseItem.__typename === type) {
      return [...results, courseItem];
    }
    if (courseItem.__typename === CourseItemType.CourseModule) {
      if (courseItem.items) {
        return [...results, ...courseItem.items.filter(item => item.__typename === type)];
      }
    }
    return results;
  }, []);
};

export const findNextItem = (
  courseItems: CourseItem[],
  currentId: string,
  currentType: CourseItemType
): ModuleItem | undefined => {
  const current = findItemOfType(courseItems, currentType, currentId);
  if (current) {
    let { index: startIndex, moduleIndex: foundModuleIndex } = current;

    if (foundModuleIndex !== undefined) {
      const currentModule = courseItems[startIndex] as CourseModule;
      const nextModuleIndex = foundModuleIndex + 1;
      if (currentModule && currentModule.items && nextModuleIndex < currentModule.items.length) {
        return currentModule.items[nextModuleIndex];
      }
    }
    startIndex += 1;
    for (let index = startIndex; index < courseItems.length; index += 1) {
      const item = courseItems[index];
      if (item.__typename !== CourseItemType.CourseModule) {
        return item as ModuleItem;
      }
      if (item.__typename === CourseItemType.CourseModule) {
        if (item.items && item.items.length > 0) {
          return item.items[0];
        }
      }
    }
  }
  return undefined;
};

export const findNextItemOfType = (
  courseItems: CourseItem[],
  currentId: string,
  currentType: CourseItemType,
  requiredTypes: CourseItemType[]
): ModuleItem | undefined => {
  const current = findItemOfType(courseItems, currentType, currentId);
  if (current) {
    let { index: startIndex, moduleIndex: foundModuleIndex } = current;
    if (foundModuleIndex !== undefined) {
      const currentModule = courseItems[startIndex] as CourseModule;
      const nextModuleIndex = foundModuleIndex + 1;
      if (currentModule && currentModule.items && nextModuleIndex < currentModule.items.length) {
        for (let index = nextModuleIndex; index < currentModule.items.length; index += 1) {
          const item = currentModule.items[index];
          if (requiredTypes.includes(item.__typename as CourseItemType)) {
            return item;
          }
        }
      }
    }
    startIndex += 1;
    for (let index = startIndex; index < courseItems.length; index += 1) {
      const item = courseItems[index];
      if (!isOfType(item, CourseItemType.CourseModule)) {
        if (requiredTypes.includes(item.__typename as CourseItemType)) {
          return item as ModuleItem;
        }
      }
      if (isOfType(item, CourseItemType.CourseModule)) {
        const module = item as CourseModule;
        if (module.items && module.items.length > 0) {
          for (let moduleIndex = 0; moduleIndex < module.items.length; moduleIndex += 1) {
            const moduleItem = module.items[moduleIndex];
            if (requiredTypes.includes(moduleItem.__typename as CourseItemType)) {
              return moduleItem;
            }
          }
        }
      }
    }
  }
  return undefined;
};

export const updateItemOfType = (
  courseItems: CourseItem[] | undefined,
  update: ModuleItem
): CourseItem[] | undefined => {
  if (courseItems) {
    for (let index = 0; index < courseItems.length; index += 1) {
      const item = courseItems[index];
      if (item.__typename === update.__typename && item.id === update.id) {
        const updatedItems = [...courseItems];
        updatedItems[index] = update;
        return updatedItems;
      }
      if (item.__typename === CourseItemType.CourseModule) {
        if (item.items) {
          for (let moduleIndex = 0; moduleIndex < item.items.length; moduleIndex += 1) {
            const moduleItem = item.items[moduleIndex];
            if (moduleItem.__typename === update.__typename && moduleItem.id === update.id) {
              const updatedItems = [...courseItems];
              const updatedModuleItems = [...item.items];
              updatedModuleItems[moduleIndex] = update;
              updatedItems[index] = { ...item, items: updatedModuleItems };
              return updatedItems;
            }
          }
        }
      }
    }
  }
  return undefined;
};

export const findModuleIndex = (courseItems: CourseItem[], id: string): number | undefined => {
  for (let index = 0; index < courseItems.length; index += 1) {
    const item = courseItems[index];
    if (item.__typename === CourseItemType.CourseModule && item.id === id) {
      return index;
    }
  }
  return undefined;
};

export const findLessonItems = (courseItems: CourseItem[]): LessonItem[] => {
  return findItemsOfType(courseItems, CourseItemType.Lesson) as LessonItem[];
};

export const findLessonItem = (
  courseItems: CourseItem[] | undefined,
  id: string | undefined
): LessonItem | undefined => {
  const result = findItemOfType(courseItems, CourseItemType.Lesson, id);
  return result ? (result.item as LessonItem) : undefined;
};

export const findFollowingQuiz = (courseItems: CourseItem[], id: string, type: CourseItemType): Quiz | undefined => {
  const current = findItemOfType(courseItems, type, id);
  if (current) {
    if (current.moduleIndex === undefined) {
      for (let index = current.index + 1; index < courseItems.length; index += 1) {
        const item = courseItems[index];
        // Stop looking if we get to another item of the same type or a module
        if (item.__typename === CourseItemType.CourseModule || item.__typename === type) {
          return undefined;
        }
        if (item.__typename === CourseItemType.Quiz) {
          return item;
        }
      }
    } else {
      const moduleItem = courseItems[current.index];
      if (moduleItem.__typename === CourseItemType.CourseModule && moduleItem.items) {
        for (let moduleIndex = current.moduleIndex + 1; moduleIndex < moduleItem.items.length; moduleIndex += 1) {
          const item = moduleItem.items[moduleIndex];
          // Stop looking if we get to another item of the same type
          if (item.__typename === type) {
            return undefined;
          }
          if (item.__typename === CourseItemType.Quiz) {
            return item;
          }
        }
      }
    }
  }
  return undefined;
};

export const findQuiz = (courseItems: CourseItem[] | undefined, id: string | undefined): Quiz | undefined => {
  const result = findItemOfType(courseItems, CourseItemType.Quiz, id);
  return result ? (result.item as Quiz) : undefined;
};

export const findFlashcardItem = (
  courseItems: CourseItem[] | undefined,
  id: string | undefined
): FlashcardItem | undefined => {
  const result = findItemOfType(courseItems, CourseItemType.FlashcardItem, id);
  return result ? (result.item as FlashcardItem) : undefined;
};

export const findFlashcardItems = (courseItems: CourseItem[]): FlashcardItem[] => {
  return findItemsOfType(courseItems, CourseItemType.FlashcardItem) as FlashcardItem[];
};

export const findRecallItem = (
  courseItems: CourseItem[] | undefined,
  id: string | undefined
): RecallItem | undefined => {
  const result = findItemOfType(courseItems, CourseItemType.RecallItem, id);
  return result ? (result.item as RecallItem) : undefined;
};

export const updateLessonItem = (courseItems: CourseItem[], lessonItem: LessonItem): CourseItem[] | undefined => {
  return updateItemOfType(courseItems, lessonItem);
};

export const updateQuizItem = (courseItems: CourseItem[], quiz: Quiz): CourseItem[] | undefined => {
  return updateItemOfType(courseItems, quiz);
};

const MAX_ITEMS_FOR_ANONYMOUS_USERS = 2;

export const isAvailableToUser = (courseItems: CourseItem[], checkItem: CourseItem, isLoggedIn: boolean): boolean => {
  if (isLoggedIn) {
    return true;
  }
  const { id } = checkItem;
  const type = getItemType(checkItem);

  for (let index = 0; index < Math.min(MAX_ITEMS_FOR_ANONYMOUS_USERS, courseItems.length); index += 1) {
    const item = courseItems[index];
    if (isOfType(item, type) && item.id === id) {
      return true;
    }
    if (isOfType(item, CourseItemType.CourseModule)) {
      const module = item as CourseModule;
      if (module.items) {
        for (let moduleIndex = 0; moduleIndex < module.items.length; moduleIndex += 1) {
          const moduleItem = module.items[moduleIndex];
          if (isOfType(moduleItem, type) && moduleItem.id === id) {
            return true;
          }
        }
      }
    }
  }
  return false;
};

export const isLogonRequiredToViewLesson = (courseItems: CourseItem[] | undefined, lessonId: string): boolean => {
  if (courseItems) {
    let lessonCount = 0;

    const logonRequired = (item: CourseItem): boolean | undefined => {
      if (item.__typename === CourseItemType.Lesson) {
        if (item.id === lessonId) {
          return false;
        }
        lessonCount += 1;
        if (lessonCount >= NumberOfPreviewLessons) {
          return true;
        }
      }
      return undefined;
    };

    for (let index = 0; index < courseItems.length; index++) {
      const item = courseItems[index];
      if (item.__typename === CourseItemType.CourseModule && item.items) {
        for (let moduleIndex = 0; moduleIndex < item.items.length; moduleIndex++) {
          const moduleItem = item.items[moduleIndex];
          const result = logonRequired(moduleItem);
          if (result !== undefined) {
            return result;
          }
        }
      } else {
        const result = logonRequired(item);
        if (result !== undefined) {
          return result;
        }
      }
    }
  }
  return false;
};
