import React, { CSSProperties } from "react";

type Props = {
  svgContent: any;
  viewBox: any;
  width: number;
  height: number;
  title?: string;
  style?: CSSProperties | undefined;
};

export class SvgIcon extends React.Component<Props> {
  render() {
    const hasTitle = this.props.title !== undefined;
    const labelledbyElement = hasTitle ? "title" : undefined;
    const width = this.props.width !== undefined ? this.props.width : "20";
    const height = this.props.height !== undefined ? this.props.height : "20";

    return (
      <svg
        className="link"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={this.props.viewBox}
        aria-labelledby={labelledbyElement}
        style={this.props.style}
      >
        {hasTitle ? <title id="title">{this.props.title}</title> : null}
        {this.props.svgContent}
      </svg>
    );
  }
}
