import React, { FC } from "react";
import { ListItem, ListItemText, IconButton as _IconButton } from "@material-ui/core";
import { formatDistance } from "date-fns";
import styled from "styled-components";
import { preventEvent } from "../../../../utils/domUtils";
import { Presentation } from "../../../../generated/graphql";
import { IconPlay } from "../../icons/IconPlay";
import { IconUsers } from "../../icons/IconUsers";
import { ExtendedTooltip } from "../../ExtendedTooltip/ExtendedTooltip";

type Props = {
  presentation: Presentation;
  onClick: () => void;
  onGroupPresentationClick?: (presentation: Presentation) => void;
};

const PresentationItem: FC<Props> = ({ presentation, onGroupPresentationClick, onClick }) => {
  const video = presentation.videos[0];
  const duration = video && video.duration;
  const tooltips = {
    openPresentation: {
      title: "Open presentation",
      detail: `Open presentation ${presentation !== undefined ? presentation.name : ""}`,
    },
    groupPresentation: {
      title: "Enter a group presentation",
      detail: `Enter a group presentation for ${presentation !== undefined ? presentation.name : ""}`,
    },
  };
  return (
    <ListItem button onClick={onClick} divider>
      <ExtendedTooltip data={tooltips.openPresentation}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
          <ListItemText
            primary={presentation.name}
            secondary={duration ? formatDistance(new Date(duration), new Date(0)) : ""}
          />
          <IconButton style={{ height: 50 }}>
            <IconPlay />
          </IconButton>
        </div>
      </ExtendedTooltip>
      {onGroupPresentationClick && (
        <ExtendedTooltip data={tooltips.openPresentation}>
          <div style={{ marginLeft: 8 }}>
            <IconButton
              onClick={event => {
                preventEvent(event);
                onGroupPresentationClick(presentation);
              }}
            >
              <IconUsers />
            </IconButton>
          </div>
        </ExtendedTooltip>
      )}
    </ListItem>
  );
};

const IconButton = styled(_IconButton)({
  border: "3px solid #E0E0E0",
});
export default PresentationItem;
