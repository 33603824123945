import React, { FC, ReactNode, CSSProperties, MouseEventHandler } from "react";
import { IconButton, CircularProgress as _CircularProgress, makeStyles } from "@material-ui/core";
import styled from "styled-components";

type Props = {
  style?: CSSProperties;
  progressColor?: string;
  secondaryProgressColor?: string;
  onClick?: MouseEventHandler;
  progress?: number;
  secondaryProgress?: number;
  children: ReactNode;
  testId?: string;
};

const useStyles = makeStyles({
  button: {
    "& img": { filter: "invert(1)" },
    "& svg": {
      color: "white",
    },
  },
});

const ProgressButton: FC<Props> = ({
  children,
  progress,
  secondaryProgress,
  style,
  progressColor,
  secondaryProgressColor,
  onClick,
  testId,
}) => {
  const classes = useStyles();

  if (progress === 100 && (secondaryProgress === undefined || secondaryProgress === 100)) {
    return (
      <IconButton
        className={classes.button}
        style={{
          ...style,
          height: 44,
          backgroundColor: secondaryProgress === undefined ? progressColor : secondaryProgressColor,
        }}
        onClick={onClick}
        data-testid={testId}
      >
        {children}
      </IconButton>
    );
  }

  return (
    <IconButton component="span" style={style} onClick={onClick} data-testid={testId}>
      {children}
      <CircularProgress size={48} variant="static" value={100} style={{ color: "#E0E0E0" }} />
      <CircularProgress size={48} variant="static" value={progress} style={{ color: progressColor }} />
      {secondaryProgress !== undefined && (
        <CircularProgress
          size={48}
          variant="static"
          value={secondaryProgress}
          style={{ color: secondaryProgressColor }}
        />
      )}
    </IconButton>
  );
};

const CircularProgress = styled(_CircularProgress)({
  position: "absolute",
});

export default ProgressButton;
