import { isEmpty } from "lodash";
import { Presentation } from "../generated/graphql";

function getDuration(presentation: Presentation | undefined) {
  if (!presentation) return null;
  if (presentation.media && presentation.media.url) return presentation.media.duration;
  if (!isEmpty(presentation.videos)) return presentation.videos[0].duration;
  return null;
}

export const PresentationUtils = {
  getDuration,
};
