import {
  AnswerAttempt,
  MapProgress,
  CourseItem,
  CourseModule,
  Presentation,
  LessonItem,
  Quiz,
} from "../../../../../generated/graphql";
import { CourseItemType } from "../../../../../constant/courseItemType";

export type ModuleProgress = { id: string; total: number; completed: number };
export type OverallProgress = { total: number; completed: number };

const CompleteFraction = 0.8;

const getLessonCompleted = (
  lesson: LessonItem,
  mapProgress: MapProgress | null | undefined,
  presentations: Presentation[] | undefined
): boolean => {
  let completed = false;
  const progress = mapProgress && mapProgress.lessons.find(prog => prog.lessonId === lesson.id);
  const presentation = presentations && presentations.find(pres => pres.id === lesson.presentationId);
  if (progress && progress.longestWatchedTime && presentation && presentation.videos) {
    const video = presentation.videos[0];
    if (!video || !video.duration || video.duration * CompleteFraction < progress.longestWatchedTime) completed = true;
  }
  return completed;
};

const getQuizCompleted = (quiz: Quiz, mapAnswers: AnswerAttempt[] | undefined): boolean => {
  let completed = false;
  if (mapAnswers) {
    const quizAnswers = mapAnswers.find(answer => answer.quizId === quiz.id);
    if (quizAnswers) {
      const totalQuestions = quiz.questions.length;
      if (quizAnswers.answers.length >= totalQuestions * CompleteFraction) {
        completed = true;
      }
    }
  }
  return completed;
};

const isCompleted = (
  item: Quiz | LessonItem,
  mapProgress: MapProgress | null | undefined,
  presentations: Presentation[] | undefined,
  mapAnswers: AnswerAttempt[] | undefined
): boolean => {
  let completed = false;
  switch (item.__typename) {
    case CourseItemType.Lesson: {
      if (getLessonCompleted(item, mapProgress, presentations)) {
        completed = true;
      }
      break;
    }
    case CourseItemType.Quiz: {
      if (getQuizCompleted(item, mapAnswers)) {
        completed = true;
      }
      break;
    }
    default:
      break;
  }
  return completed;
};

export const getModuleProgress = (
  mapAnswers: AnswerAttempt[] | undefined,
  mapProgress: MapProgress | null | undefined,
  courseItems: CourseItem[] | undefined,
  presentations: Presentation[] | undefined
): ModuleProgress[] => {
  const moduleItems =
    courseItems &&
    (courseItems.filter(item => item.__typename === CourseItemType.CourseModule && item.items) as CourseModule[]);
  if (moduleItems) {
    return moduleItems.map(module => {
      let itemCount = 0;
      let completed = 0;
      (module.items || []).forEach(item => {
        if (item.__typename === CourseItemType.Lesson || item.__typename === CourseItemType.Quiz) {
          itemCount += 1;
          if (isCompleted(item, mapProgress, presentations, mapAnswers)) {
            completed += 1;
          }
        }
      });
      return { id: module.id, total: itemCount, completed };
    }) as ModuleProgress[];
  }
  return [];
};

export const getOverallProgress = (
  mapAnswers: AnswerAttempt[] | undefined,
  mapProgress: MapProgress | null | undefined,
  courseItems: CourseItem[] | undefined,
  presentations: Presentation[] | undefined
): OverallProgress => {
  let itemCount = 0;
  let completed = 0;

  if (courseItems) {
    courseItems.forEach(courseItem => {
      switch (courseItem.__typename) {
        case CourseItemType.Lesson:
        case CourseItemType.Quiz:
          itemCount += 1;
          if (isCompleted(courseItem, mapProgress, presentations, mapAnswers)) {
            completed += 1;
          }

          break;
        case CourseItemType.CourseModule:
          (courseItem.items || []).forEach(item => {
            if (item.__typename === CourseItemType.Lesson || item.__typename === CourseItemType.Quiz) {
              itemCount += 1;
              if (isCompleted(item, mapProgress, presentations, mapAnswers)) {
                completed += 1;
              }
            }
          });
          break;
        default:
          break;
      }
    });
  }

  return { total: itemCount, completed };
};
