import React, { FC } from "react";
import { Typography, Grid } from "@material-ui/core";
import nl2br from "react-newline-to-break";
import { Course } from "../../../../generated/graphql";

type Props = {
  course: Course;
};

const CourseDescription: FC<Props> = ({ course }) => {
  return (
    <>
      {course.description && (
        <>
          <Grid container item justify="space-between" alignItems="center" style={{ paddingTop: 24 }}>
            <Grid item>
              <Typography variant="h6">Description</Typography>
            </Grid>
          </Grid>
          <Grid item style={{ paddingBottom: "24px" }}>
            <Typography variant="body1">{nl2br(course.description)}</Typography>
          </Grid>
        </>
      )}
    </>
  );
};

export default CourseDescription;
