import styled from "styled-components";

export const FlexGrowContainer = styled.div({ display: "flex", flex: 1 });
export const FlexContainer = styled.div({ display: "flex" });
export const FlexColumnContainer = styled.div({ display: "flex", flexDirection: "column" });
export const FlexContainerCenter = styled.div({ display: "flex", alignItems: "center" });
export const AppContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  width: "100%",
});
export const ContentContainer = styled.div({
  flex: 1,
});
