import React, { FC, memo } from "react";
import { ListItemText } from "@material-ui/core";
import { TooltipData } from "../../../ExtendedTooltip/types";
import { ExtendedTooltip } from "../../../ExtendedTooltip/ExtendedTooltip";
import { FlashcardItem } from "../../../../../generated/graphql";
import useNavigation from "../../../../hooks/useNavigation";
import ProgressButton from "../../../ProgressButton";
import { IconFlashCard } from "../../../icons/IconFlashCard";
import { CourseItemListItem } from "./CourseItemListItem";

type Props = {
  courseId: string | undefined;
  mapId: string;
  flashcardItem: FlashcardItem;
  progress?: Record<string, { correct: number; attempted: number }> | undefined;
};

const CourseFlashcardItem: FC<Props> = ({ courseId, mapId, flashcardItem, progress }) => {
  const { goToLessonCards } = useNavigation(courseId, mapId);

  const handleClick = () => goToLessonCards(flashcardItem.id);

  const { correct, attempted } = (progress && progress[flashcardItem.id]) || {};

  let tooltip: TooltipData | undefined;

  if (correct !== undefined && attempted !== undefined) {
    tooltip = {
      title: "Progress",
      detail: (
        <div>
          <div>{`Attempted: ${attempted}%`}</div>
          <div>{`Known: ${correct}%`}</div>
        </div>
      ),
    };
  }

  return (
    <CourseItemListItem button divider onClick={handleClick} data-testid="flashcard-item">
      <ExtendedTooltip data={tooltip}>
        <span>
          <ProgressButton
            style={{ marginRight: 16 }}
            progress={attempted}
            secondaryProgress={correct}
            progressColor="#7fc07f"
            secondaryProgressColor="#008F60"
          >
            <IconFlashCard />
          </ProgressButton>
        </span>
      </ExtendedTooltip>
      <ListItemText primary={flashcardItem.name} />
    </CourseItemListItem>
  );
};

export default memo(CourseFlashcardItem);
